import React from "react";

import Home from "../components/Home";
import Layout from "layouts/Layout";

const Index = () => {
  return (
    <Layout seoTitle="Home">
      <Home />
    </Layout>
  );
};

export default Index;

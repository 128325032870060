import React, { useEffect, useContext, Fragment } from "react";
import { navigate } from "gatsby";
import { gsap, Power4, Expo } from "gsap";

import Button from "elements/Button";

import "./style/home.scss";
import { isBrowser } from "services/general";
import { renderPlaneWave } from "./services/plane";
import { AppContext } from "../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    let pageContentTimeLine = gsap.timeline();
    let wavesTimeline = gsap.timeline();

    pageContentTimeLine.from(".animation-up", {
      duration: 0.5,
      delay: state?.isInitialLoad ? 0.7 : 1.3,
      stagger: 0.05,
      opacity: 0,
      y: 20,
    });

    if (isBrowser() && state?.enablePlaneWave) {
      renderPlaneWave();

      wavesTimeline.from("#plane-wave", {
        duration: 0.5,
        delay: state?.isInitialLoad ? 0.7 : 1.3,
        opacity: 0,
      });
    }

    //eslint-disable-next-line
  }, []);

  const handleShowContactForm = () => {
    dispatch({ type: "TOGGLE_CONTACT_ME" });

    let contactFormTimeline = gsap.timeline();
    let contactFormBackgroundTimeline = gsap.timeline();

    contactFormTimeline.to(".contact-me-form-container", {
      duration: 0.5,
      height: "auto",
      ease: Power4.easeOut,
    });

    contactFormTimeline.from(".contact-me-form-content", {
      ease: Power4.easeOut,
      duration: 0.5,
      opacity: 0,
      y: 20,
    });

    contactFormBackgroundTimeline.to(".contact-me-container", {
      ease: Expo.easeOut,
      duration: 1,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    });
  };

  return (
    <Fragment>
      <div id="plane-wave"></div>
      <div className="home-page-container animation-up page-transition-out has-text-centered">
        <p>Hello and welcome to my website! I'm</p>
        <div className="title-container">
          <h1 className="title mb-0 has-text-weight-bold">
            John Thomas Carlos,
          </h1>
          <h1 className="title mb-5 has-text-weight-bold">
            a Software Developer.
          </h1>
        </div>
        <p>
          I am a computer engineering graduate from Adamson University,{" "}
          <br className="is-hidden-mobile" /> focusing on creating web
          applications that reaches people.
        </p>
        <div className="mt-5 ">
          <Button
            outlined
            color="primary"
            className="home-cta-button mr-2"
            handleClick={() => navigate("/about-me")}
          >
            Learn more about me{" "}
            <span className="ml-3">
              <FontAwesomeIcon icon={faArrowRightLong} />
            </span>
          </Button>
          <Button
            handleClick={handleShowContactForm}
            className="home-cta-button"
            color="primary"
          >
            Contact Me
            <span className="ml-3">
              <FontAwesomeIcon icon={faPhone} />
            </span>
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;

import {
  Fog,
  Mesh,
  Scene,
  Vector3,
  PlaneGeometry,
  WebGLRenderer,
  MeshBasicMaterial,
  PerspectiveCamera,
} from "three";

import { isBrowser } from "services/general";

export const renderPlaneWave = () => {
  if (isBrowser) {
    let planeWaveContainer = document.getElementById("plane-wave");
    let count = 0;

    let vertexHeight = 4500;
    let planeDefinition = 100;
    let planeSize = 300_000;

    let backgroundColor = "#ffffff";
    let meshColor = "#b3b3b3";

    // Camera setup
    let cameraPOV = 55;
    let cameraSize = window.innerHeight / window.innerHeight;
    let cameraNear = 1;
    let cameraNumber = 400_000;

    let camera = new PerspectiveCamera(
      cameraPOV,
      cameraSize,
      cameraNear,
      cameraNumber
    );

    camera.position.z = 10_000;
    camera.position.y = 10_000;

    if (window.innerWidth > 1000) {
      camera.aspect = 1.624;
      camera.updateProjectionMatrix();
    }

    // Scene and plane setup
    let fogNearest = 1;
    let fogFarthest = 200_000;

    let scene = new Scene();
    scene.fog = new Fog(backgroundColor, fogNearest, fogFarthest);

    let planeGeo = new PlaneGeometry(
      planeSize,
      planeSize,
      planeDefinition,
      planeDefinition
    );

    let plane = new Mesh(
      planeGeo,
      new MeshBasicMaterial({
        color: meshColor,
        wireframe: true,
        wireframeLinewidth: 1,
      })
    );

    plane.rotation.x -= Math.PI * 0.63;

    scene.add(plane);

    // Renderer setup
    let renderer = new WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(backgroundColor, 1);

    planeWaveContainer.appendChild(renderer.domElement);

    updatePlane();
    render();

    function updatePlane() {
      for (let counter = 0; counter < planeGeo.vertices.length; counter++) {
        planeGeo.vertices[counter].z +=
          Math.random() * vertexHeight - vertexHeight;
        planeGeo.vertices[counter]._myZ = planeGeo.vertices[counter].z;
      }
    }

    function render() {
      requestAnimationFrame(render);
      camera.lookAt(new Vector3(0, 8000, 0));
      camera.position.x = 0;
      camera.position.z = 10000;

      for (let counter = 0; counter < planeGeo.vertices.length; counter++) {
        planeGeo.vertices[counter].z =
          Math.sin(counter + count * 0.00002) *
          (planeGeo.vertices[counter]._myZ -
            planeGeo.vertices[counter]._myZ * 0.6);
        plane.geometry.verticesNeedUpdate = true;

        count += 0.1;
      }

      renderer.render(scene, camera);
    }

    window.addEventListener("resize", onWindowResize, false);

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }
  }
};
